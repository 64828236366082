import React from "react"
import { graphql } from "gatsby"

import { Link } from "@reach/router"
import SEO from "../../components/seo"
import RegDropDown from "../../components/Registration/RegDropDown"
import PageTitle from "../../components/shared/PageTitle"
import OL, { Li2 } from "../../components/shared/OrderedList"
import { IndentedItem } from "./players"
import ListItemTitle from "../../components/shared/ListItemTitle"

const VolunteerPage = () => {
  const spordleLink =
    "https://page.spordle.com/sioux-lookout-hudson-minor-hockey-association"

  return (
    <div className="px-5 mb-10">
      <SEO
        title="Volunteer Registration"
        keywords={[`Sioux Lookout`, `hockey`, `Registration`, `info`]}
      />
      <PageTitle>Volunteer Registration</PageTitle>

      <div>
        <p>
          To Register as a Volunteer (Coach, Trainer, On-Ice Helper, etc.) with
          Sioux Lookout Minor Hockey, use the following steps below:
        </p>
      </div>

      <ol className="my-10 break-words grid gap-y-5 ml-0 md:ml-8">
        <RegDropDown
          title="Register with Hockey Canada"
          mainLinkUrl={spordleLink}
          mainLinktext="Register"
          redButton
        >
          <OL>
            <li>
              {" "}
              <a target="_blank" href={spordleLink} rel="noreferrer noopener">
                a. Follow the link,
              </a>{" "}
              Click “Register Now”
            </li>
            <li>
              b. See list of costs and birth years associated with each
              Division, click “Register Now”
            </li>
            <li>
              c. Login with Hockey Canada registry (email and password with
              Spordle account)
            </li>
            <IndentedItem className="text-sm italic">
              * if new to Hockey Canada, create an account (remember login
              information for future!)
            </IndentedItem>

            <li>d. Fill in the information as requested</li>
            <li>e. Payment:</li>
            <Li2>
              <OL>
                <li>
                  <ListItemTitle>
                    i. Pay Fees online using credit card in registration process
                    (previous steps)
                  </ListItemTitle>
                </li>
                <li>
                  <ListItemTitle className="inline-block">
                    ii. Pay by Email Money Transfer (EMT) to:
                  </ListItemTitle>{" "}
                  slmhatreasurer@gmail.com
                </li>
                <li>
                  <ListItemTitle className="inline-block">
                    iii. For any special payment requests please email the
                    Treasurer at:
                  </ListItemTitle>{" "}
                  <a href="mailto:slmhatreasurer@gmail.com">
                    slmhatreasurer@gmail.com
                  </a>
                </li>
              </OL>
            </Li2>
          </OL>
        </RegDropDown>

        <RegDropDown
          title="Complete HNO Volunteer Requirements"
          mainLinktext="HNO Coaches Corner"
          mainLinkUrl="https://hockeyhno.com/coaches-corner/requirements/"
        >
          <OL>
            <li>
              a. Select the Division, Level, and Position you would like to
              volunteer for
            </li>
            <li>
              b. Complete the list of requirements shown on the website
              <Li2>
                <OL className="grid gap-y-5 mt-3">
                  <li>
                    <ListItemTitle>
                      i. Respect in Sport – Activity Leader
                    </ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>Click link</li>
                      <li>
                        Login using the Hockey Canada Spordle account
                        information (Step 1 – Hockey Canada)
                      </li>
                      <li>
                        Register for Course (you will be reimbursed for the fee
                        through your Division Convenor)
                      </li>
                      <li>Select “Program Access”</li>
                      <li>Complete Modules (watch)</li>
                    </OL>
                  </li>
                  <li>
                    <ListItemTitle>
                      ii. Gender Identity &amp; Expression
                    </ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>Click link</li>
                      <li>
                        There are two (2) drop down menus at top,
                        <OL className="pl-5">
                          <li>
                            a. Left drop down, select Hockey Northwest Ontario
                            (HNO)
                          </li>
                          <li>
                            b. Right drop down, select Sioux Lookout/Hudson
                            Minor Hockey Association (SLMHA)
                          </li>
                        </OL>
                      </li>
                      <li>Find Course, register</li>
                      <li>Complete Modules (watch)</li>
                    </OL>
                  </li>
                  <li>
                    <ListItemTitle>iii. Rowan’s Law</ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>Click link</li>
                      <li>Read information</li>
                    </OL>
                  </li>
                  <li>
                    <ListItemTitle>
                      iv. Police Record Check Request Form
                    </ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>Click link</li>
                      <li>Save letter for use for Vulnerable Sector Check</li>
                    </OL>
                  </li>
                  <li>
                    <ListItemTitle>v. Criminal Record Check</ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>Click link</li>
                      <li>
                        Follow instructions
                        <OL className="pl-5">
                          <li>a. You require a “Vulnerable Sector Check”</li>
                          <li>
                            b. No fees are associated with VSC for Volunteers
                          </li>
                        </OL>
                      </li>
                    </OL>
                  </li>
                  <li>
                    <ListItemTitle>vi. For Coaches:</ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>Register and complete Online Coach Course</li>
                      <li>
                        Register for Coach 1 (U7 & U9), Coach 2 (U11 and up)
                      </li>
                      <li>
                        Discuss with Division Convenor on Coaching Clinic
                        availability
                      </li>
                      <li>
                        Course fee reimbursement can be done through your
                        Division Convenor
                      </li>
                    </OL>
                  </li>
                  <li>
                    <ListItemTitle>vii. For Trainers:</ListItemTitle>
                    <OL className="list-decimal pl-5">
                      <li>
                        Register and complete Hockey Canada Safety Program Level
                        1
                      </li>
                      <li>
                        Discuss with your Convenor for course availability
                      </li>
                      <li>
                        Course fee reimbursement can be done through your
                        Division Convenor
                      </li>
                    </OL>
                  </li>
                </OL>
              </Li2>
            </li>
          </OL>
        </RegDropDown>
      </ol>

      <p style={{ textAlign: "center" }}>
        <Link to="/contact">Contact us</Link> for more information.
      </p>
    </div>
  )
}

export default VolunteerPage

export const pageQuery = graphql`
  query volunteerRegistrationQuery {
    registrationImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrationForm: contentfulDocument(
      title: { eq: "SLMHA 2020 2021 Season Registration" }
    ) {
      file {
        file {
          url
        }
      }
    }
    contentfulRegistrationPage {
      mainContent {
        raw
      }
    }
  }
`
